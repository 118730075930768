<template>
  <AuthPageTemplate
    :errorCard="mailSent"
    :successText="$t('auth.restore.success')"
    :button="$t('auth.restore.button.restore')"
    :loading="loading"
    :disabled="!(username != null && isMail == true) || mailSent"
    :error="errorHandle"
    v-on:click="restorePassword"
  >
    <!-- overlay card if successfull restore -->
    <template v-slot:error>
      <v-col
        justify="center"
        style="top: 50%; position: absolute; transform: translateY(-50%)"
        align="center"
      >
        <v-icon size="40" color="success">mdi-check-circle</v-icon>
        <div style="width: 75%" class="mt-5">
          <span class="black--text" style="font-size: 15px">{{
            $t('auth.restore.success')
          }}</span>
        </div>
      </v-col>

      <TextButton
        class="back-button"
        icon="mdi-chevron-left"
        v-on:click="$router.push({name: 'login'}).catch(() => {})"
        dense
        :text="$t('auth.reset.button.toLogin')"
      />
    </template>

    <!-- input slot -->
    <template v-slot:input>
      <div class="black--text mb-6" style="font-size: 15px">
        <div style="font-size: 20px" class="mb-1 black--text">
          <b>{{ $t('auth.restore.title') }}</b>
        </div>
        <span class="font-weight-normal grey--text">{{
          $t('auth.restore.text')
        }}</span>
      </div>

      <TextInput
        :disabled="loading || mailSent"
        class="mt-4 mb-4"
        v-on:change="updateUsernameEvent"
        :placeholder="$t('auth.restore.input.mail')"
        :value="username"
      />
    </template>

    <template v-slot:bottom>
      <TextButton
        dense
        icon="mdi-chevron-left"
        v-on:click="$router.push({name: 'login'}).catch(() => {})"
        class="mt-2"
        block
        :text="$t('auth.restore.button.toLogin')"
      />
    </template>
  </AuthPageTemplate>
</template>

<script>
import AuthPageTemplate from '@/components/auth/AuthPageTemplate.vue';
import TextInput from '@components/text/TextInput.vue';
import TextButton from '@components/button/TextButton.vue';

export default {
  name: 'RestoreView',
  components: {
    AuthPageTemplate,
    TextButton,
    TextInput,
  },

  data() {
    return {
      loading: false,

      errorHandle: {
        status: false,
        text: '',
      },
      mailSent: false,
      username: '',
    };
  },

  created() {
    // in case a valid mail is provided through the route query it is pasted to the input automatically
    if (
      this.$route.query.mail != null &&
      this.$helper.isValidMail(this.$route.query.mail)
    ) {
      this.username = this.$route.query.mail;
    }

    // remove query params
    if (this.$route.query.mail != null) this.$router.replace({});
  },

  computed: {
    isMail() {
      return this.$helper.isValidMail(this.username);
    },
  },

  methods: {
    updateUsernameEvent(usernameString) {
      this.username = usernameString;
      this.errorHandle.status = false; // reset error if input changes
    },

    async restorePassword() {
      this.loading = true;
      this.errorHandle.status = false; // reset error

      var restoreResp = await this.$api.user.restore(this.username); // restore password

      if (!restoreResp || restoreResp.success != true) {
        this.errorHandle.status = true;
        this.errorHandle.text = this.$t('auth.restore.error');
      } else {
        this.mailSent = true;
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.back-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  border: 1px solid #c0c0c0;
}
</style>
